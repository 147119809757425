.load-screen {
  position: absolute;
  background: #F7FAFC;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100; }
  .load-screen .spinner {
    animation: 1s linear spin infinite;
    width: 32px;
    height: 32px; }
    .load-screen .spinner .circle {
      fill: #F0F6FA; }
    .load-screen .spinner .spinner-circle {
      fill: #006BE6; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
