body {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  height: 100vh;
  background: #F7FAFC;
  color: #6C6C77; }

.row {
  display: flex;
  flex-direction: row; }

.column {
  display: flex;
  flex-direction: column; }

.card {
  width: 100vw;
  padding-top: 36px;
  padding-left: 24px;
  padding-bottom: 32px;
  display: flex;
  border-bottom: solid 1px;
  border-color: #EDEDF2; }

.payments {
  background-color: white;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
  padding-left: 28px;
  padding-top: 24px;
  padding-bottom: 24px; }
  .payments__status {
    font-size: 14px; }
  .payments__list {
    margin-top: 19px; }
  .payments__waiting {
    font-size: 14px;
    color: #A3B0BF; }

.footer {
  padding: 24px;
  color: #A3B0BF; }

.label-text {
  font-size: 16px;
  color: #6C6C77; }

.heavy-text {
  font-size: 18px;
  font-weight: bold;
  color: #292D33; }

.copy {
  margin-left: 16px;
  color: #006BE6; }
  .copy__button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #006BE6;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 4px;
    line-height: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600; }
    .copy__button:hover {
      background: #f6fafc; }
    .copy__button .link-icon {
      width: 16px;
      height: 16px;
      fill: #006BE6; }

table {
  font-size: 14px;
  margin-left: 14px; }
  table tr {
    table-layout: fixed; }
  table td {
    padding: 10px; }
  table td:nth-child(2) {
    font-size: 16px;
    font-weight: bold;
    color: #292D33; }
